<template>
    <div class="RuleOfSunshine">
        <!-- <h1>阳光准则</h1> -->
        <img src="https://img.youstarplanet.com/h5/CooperationNegotiate.png" alt="">
        <ul class="content">
            <li>阳光准则</li>
            <li>
                星都时代信奉正直、进取、协作、创造的价值观，其中，为宣扬正直，我们制定了相关制度，采取了一系列措施，以预防、发现及阻止贪腐或任何违反公司正直价值观的行为。星都时代的全体员工均需严格遵守《星都时代阳光行为准则》（“阳光准则”），阳光准则明确的禁止各类型的贪腐、舞弊以及任何不符合法律法规的行为。<br>
                我们制定了《反舞弊举报制度》，清楚向全体员工传递星都时代对贪腐和舞弊行为零容忍的信息。我们鼓励所有员工及供应商/业务合作伙伴通过以下方式举报涉及星都时代员工的任何已经发生或可能发生的贪腐、舞弊及违规行为：<br>
                举报邮箱: jubao@youstarplanet.com<br>
                举报电话: +86-0755-23144277<br>
                信件：中国广东省深圳市宝安区西乡街道资信达大厦518<br>
                我们鼓励您举报时留下您的姓名和联系方式，以便我们与您取得联系获取更多信息及全面的开展调查工作。同时，我们也会保证提供了有效举报信息的匿名举报同样得到认真的处理。我们已采取相关保密措施保证您的身份和您提供的信息不被公开。<br>
                我们不希望任何人杜撰、捏造事实，或恶意攻击、诽谤星都时代员工，如发现上述行为，星都时代将保留追究其责任的权利。我们严厉禁止以任何形式报复举报人、证人及调查人员，如存在，星都时代将严厉查处。<br>
                <br>
                温馨提示：如果您想对星都时代相关业务进行咨询及投诉，建议您通过星都时代公司客服渠道进行反映，您的问题或咨询将得到更快的帮助。<br>
            </li>
        </ul>
    </div>
</template>

<script>
export default{
    data() {
        return {}
    },
    async mounted() {
        //页面跳转默认顶部
        document.documentElement.scrollTop = 0
    },
}
</script>

<style lang="less">
.RuleOfSunshine{
    &>img{
        width: 100%;
        object-fit:cover;
    }
    .content{
        padding: 35px 26px 52px;
        li{
            &:nth-child(1){
                font-size: 18px;
                font-weight: bold;
                color: #222222;
                margin-bottom: 23px;
            }
            &:nth-child(2){
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                line-height: 30px;
            }
        }
    }
}
</style>